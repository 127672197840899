import Alpine from 'alpinejs';
import dateFormat, { masks } from "dateformat"; //eslint-disable-line
import Flickity from 'flickity'; //eslint-disable-line

function getAllDaysInMonth(year, month) { //eslint-disable-line
  const date = new Date(year, month, 1);
  const dates = [];

  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd,
         ].join('-');
};
Date.prototype.ddmmyyy = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [(dd>9 ? '' : '0') + dd,
          (mm>9 ? '' : '0') + mm,
          this.getFullYear(),
         ].join('-');
};
var eventsSlider;
var eventsCalendar;

Alpine.data('handleEvents', (blockId = false) => ({
    widgetBlock: document.getElementById(blockId),
    selectedPillarsCount: '',
    selectedVenuesCount: '',
    calendarType: '',
    initialLoad:  '',
    isInitialLoadAndEventsCountAtLeastFour: false,
    isInitialLoad: true,
    selectedPillars: [],
    selectedVenues: [],
    fromDate: '',
    toDate: '',
    dateRange: [],
    sortby: 'ASC',
    previousDayElement: '',
    currentDayElement: '',
    nextDayElement: '',
    currentDayName: '',
    currentDate: '',
    checkedParams: false,
    pendingRetrieveEvents: false,
    pendingReadEvents: true,
    monthHasChanged: false,
    retrieveEventsTimeoutId: '',
    retrieveEventsTimeoutIsActive: true,
    eventFoundOnDate: false,

    viewMode: '',

    pillarDropdown: false,
    venueDropdown: false,
    tab: 1,

    events: [],
    eventsCount: 0,
    currentMonthDate: '',
    dateElement: null,
    isFlickityInitialized: false,

    atPage: 1,
    more: false,

    init() {
      this.selectedVenuesCount =  this.widgetBlock.dataset.selectedvenuescount;
      this.calendarType = this.widgetBlock.dataset.calendartype;
      this.initialLoad = this.widgetBlock.dataset.initialload;
      this.viewMode = this.widgetBlock.dataset.viewmode;
      this.selectedPillarsCount = this.widgetBlock.dataset.selectedpillarscount;

      this.currentDate = new Date(this.widgetBlock.dataset.initialdate  + 'T00:00:00').yyyymmdd();

        if( this.viewMode == 'event' ) {
          this.widgetBlock.querySelector('.datepicker--from').value =  dateFormat(new Date(this.widgetBlock.dataset.initialdate  + 'T00:00:00'), 'dd/mm/yyyy');
        }

        this.handlePillars(false, false, false);
        this.handleVenues(false, false, false);
        this.handleDates();

        if( this.calendarType == 'booking' ){
          this.tab = 4;
          this.updateDates('month', true);
        }else{
          this.retrieveEvents();
        }

        //for loop until this.events is populated, break after 10 seconds
        let i = 0;
        let interval = setInterval(() => {
          if( this.events.length > 0 ){
            this.initializeCalendar(false);
            clearInterval(interval);
          }
          i++;
          if( i > 10 ){
            this.initializeCalendar(false);
            clearInterval(interval);
          }
        }, 1000);

        //remove intro animation after 12 seconds
        /*setTimeout(() => {
          this.widgetBlock.querySelector('.upcoming-events').classList.remove('upcoming-events--introanim');
        }, 15000);*/
    },

    checkParams() {
      if( this.checkedParams == false ){
        this.checkedParams = true;
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });
        let tab = params.calendartab; // "some_value"

        if( tab ){
          if( tab == 'all' ){
            this.widgetBlock.querySelector('#view-mode--event').click();
            this.widgetBlock.querySelectorAll('.upcoming-events__menu .btn--tab')[0].click();
          }
          if( tab == 'today' ){
            this.widgetBlock.querySelector('#view-mode--event').click();
            this.widgetBlock.querySelectorAll('.upcoming-events__menu .btn--tab')[1].click();
          }
          if( tab == 'week' ){
            this.widgetBlock.querySelector('#view-mode--event').click();
            this.widgetBlock.querySelectorAll('.upcoming-events__menu .btn--tab')[2].click();
          }
          if( tab == 'month' ){
            this.widgetBlock.querySelectorAll('.upcoming-events__menu .btn--tab')[3].click();
          }
        }
      }
    },

    updateDates(range, notInitialLoad = false) {
      let today = dateFormat(new Date(), 'dd/mm/yyyy');
      //let oneWeekFromToday = dateFormat(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000), 'dd/mm/yyyy');
      let FirstDayOfThisWeek = dateFormat(new Date(new Date().getTime() - new Date().getDay() * 24 * 60 * 60 * 1000), 'dd/mm/yyyy');
      let LastDayOfThisWeek = dateFormat(new Date(new Date().getTime() + (6 - new Date().getDay()) * 24 * 60 * 60 * 1000), 'dd/mm/yyyy');
      let firstDayOfThisMonth = dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'dd/mm/yyyy');
      let lastDayOfThisMonth = dateFormat(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'dd/mm/yyyy');
      let fiveYearsFromToday = dateFormat(new Date(new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000), 'dd/mm/yyyy');

      if( range == 'all' ){
        this.widgetBlock.querySelector('.datepicker--from').value = today;
        this.widgetBlock.querySelector('.datepicker--to').value = fiveYearsFromToday;
      }else if( range == 'today' ){
        this.widgetBlock.querySelector('.datepicker--from').value = today;
        this.widgetBlock.querySelector('.datepicker--to').value = today;
      }else if( range == 'week' ){
        this.widgetBlock.querySelector('.datepicker--from').value = FirstDayOfThisWeek;
        this.widgetBlock.querySelector('.datepicker--to').value = LastDayOfThisWeek;
      }else if( range == 'month' ){
        this.widgetBlock.querySelector('.datepicker--from').value = firstDayOfThisMonth;
        this.widgetBlock.querySelector('.datepicker--to').value = lastDayOfThisMonth;
      }

      if( notInitialLoad == true ){
        this.isInitialLoad = false;
        this.updateIsInitialLoad();
      }
      this.dateElement = null;
      //this.retrieveEvents();
      if( this.retrieveEventsTimeoutIsActive ) {
        this.resetRetrieveEventsTimeout();
      } else {
        this.startRetrieveEventsTimeout();
      }
    },

    handleDates() {
      this.fromDate = this.widgetBlock.querySelector('.datepicker--from').value;
      this.toDate = this.widgetBlock.querySelector('.datepicker--to').value;

      let fromDateArray = this.fromDate.split('/');
      let formattedFromDate = new Date(fromDateArray[1] + '/' + fromDateArray[0] + '/' + fromDateArray[2]);
      formattedFromDate = dateFormat(formattedFromDate, 'yyyy-mm-dd');

      let toDateArray = this.toDate.split('/');
      let formattedToDate = new Date(toDateArray[1] + '/' + toDateArray[0] + '/' + toDateArray[2]);
      formattedToDate = dateFormat(formattedToDate, 'yyyy-mm-dd');

      this.dateRange = [formattedFromDate, formattedToDate];
    },

    handleTab(tab) {
      if( this.viewMode == 'month' ){
        let today;
        let dateElement;

        switch (tab) {
          case 'all':
            this.updateDates('all', true);
            this.tab = 1;
            break;
          case 'today':
            today = new Date();
            eventsCalendar.set(today);
            eventsCalendar.goto(today);
            dateElement = this.widgetBlock.querySelector('[data-date="' + today.yyyymmdd() + '"]');
            if( dateElement ){
              this.dateElement = dateElement;
              dateElement.click();
            }
            this.tab = 2;
            break;
          case 'week':
            //let FirstDayOfThisWeek = dateFormat(new Date(new Date().getTime() - new Date().getDay() * 24 * 60 * 60 * 1000), 'dd/mm/yyyy');
            this.tab = 3;
            break;
          case 'month':
            this.updateDates('month', true);
            this.tab = 4;
            break;
        }
      }else{
        switch (tab) {
          case 'all':
            this.updateDates('all', true);
            this.tab = 1;
            break;
          case 'today':
            this.updateDates('today', true);
            this.tab = 2;
            break;
          case 'week':
            this.updateDates('week', true);
            this.tab = 3;
            break;
          case 'month':
            this.updateDates('month', true);
            this.tab = 4;
            break;
        }
      }
    },

    handlePillars(selectAll = false, clearAll = false, retrieveEvents = false) {
      setTimeout(() => {

        let pillarCheckboxes = this.widgetBlock.querySelectorAll('.checkbox--pillar');
        // reset selectedPiallrs array
        this.selectedPillars = [];
        // check if each checkbox is checked
        pillarCheckboxes.forEach(checkbox => {
          // if selectAll is true, check all checkboxes
          if (selectAll) {
            checkbox.checked = true;
          }
          // if clearAll is true, uncheck all checkboxes
          if (clearAll) {
            checkbox.checked = false;
          }
          // if checkbox is checked, add its value to selectedPillars array
          if (checkbox.checked) {
            this.selectedPillars.push(checkbox.value);
          }else{
            // remove unchecked value from selectedPillars array
            this.selectedPillars = this.selectedPillars.filter(pillar => pillar !== checkbox.value);
          }
        });
        // Ensure selectedPllars is unique
        this.selectedPillars = Array.from(new Set(this.selectedPillars));
        // update selectedPillarsCount
        this.selectedPillarsCount = this.selectedPillars.length;
        if(retrieveEvents){
          this.isInitialLoad = false;
          this.updateIsInitialLoad();
          if( this.retrieveEventsTimeoutIsActive ) {
            this.resetRetrieveEventsTimeout();
          } else {
            this.startRetrieveEventsTimeout();
          }
        }
      },100);
    },

    handleVenues(selectAll = false, clearAll = false, retrieveEvents = false) {
      setTimeout(() => {

        let venueCheckboxes = this.widgetBlock.querySelectorAll('.checkbox--venue');
        // reset selectedVenues array
        this.selectedVenues = [];
        // check if each checkbox is checked
        venueCheckboxes.forEach(checkbox => {
          // if selectAll is true, check all checkboxes
          if (selectAll) {
            checkbox.checked = true;
          }
          // if clearAll is true, uncheck all checkboxes
          if (clearAll) {
            checkbox.checked = false;
          }
          // if checkbox is checked, add its value to selectedVenues array
          if (checkbox.checked) {
            this.selectedVenues.push(checkbox.value);
          }else{
            // remove unchecked value from selectedPillars array
            this.selectedVenues = this.selectedVenues.filter(venue => venue !== checkbox.value);
          }
        });
        // Ensure selectedPllars is unique
        this.selectedVenues = Array.from(new Set(this.selectedVenues));

        // update selectedVenuesCount
        this.selectedVenuesCount = this.selectedVenues.length;

        if(retrieveEvents){
          this.isInitialLoad = false;
          this.updateIsInitialLoad();
          if( this.retrieveEventsTimeoutIsActive ) {
            this.resetRetrieveEventsTimeout();
          } else {
            this.startRetrieveEventsTimeout();
          }
        }
      },100);
    },


    readEvents (justRefresh = false, initializeCalendar = false, dateElement = null) {

      this.events = [];
      let currentEvents = this.widgetBlock.querySelectorAll('.upcoming-events__events .upcoming-events__event');

      currentEvents.forEach((event) => {
        let event_post = {};

        if( this.calendarType != 'booking' ){
          event_post.index = event.dataset.eventindex;
          event_post.event_dates = [];

          let eventDates = event.querySelectorAll('.hidden-date-attribute');
          eventDates.forEach((eventDate) => {
            event_post.event_dates.push(eventDate.dataset.date);
          });

        }else{
          event_post.event_days = [];
          let eventDays = event.querySelectorAll('.hidden-day-attribute');
          eventDays.forEach((eventDay) => {
            let event_day = {};
            event_day.day = eventDay.dataset.day;
            event_day.start_time = eventDay.dataset.starttime;
            event_day.end_time = eventDay.dataset.endtime;
            event_day.start_date = eventDay.dataset.startdate;
            event_day.end_date = eventDay.dataset.enddate;

            event_post.event_days.push(event_day);
          });
        }

        this.events.push(event_post);
      });

      this.eventsCount = this.events.length;
      this.updateIsInitialLoad();

      if(initializeCalendar){
        setTimeout(() => {
          this.initializeCalendar(justRefresh);
          this.checkParams();

          if(dateElement != null){
            dateElement.click();
            this.dateElement = null;
          }
        }, 100);
      }

      setTimeout(() => {
        this.retrieveEventsTimeoutIsActive = false;
        //this.pendingRetrieveEvents = false;
        window.dispatchEvent(new Event('resize'));
      }, 210);
    },

    flagDates() {
      let dates = this.widgetBlock.querySelectorAll('.jsCalendar tbody td:not(.jsCalendar-previous):not(.jsCalendar-next)');
      dates.forEach((date) => {
        date.classList.remove('has-event');
        date.classList.remove('no-event');
        date.setAttribute('tabindex', '');
        //get date data attribute
        let dataDate = date.dataset.date;
        let dataDay = date.dataset.day;
        //loop through events
        this.events.forEach((event) => {
          //loop through event dates
          if( event.event_dates != undefined && event.event_dates.length > 0 ){
            event.event_dates.forEach((eventDate) => {
              //if event date matches date data attribute
              if( eventDate === dataDate ) {
                date.classList.add('has-event');
                date.setAttribute('tabindex', 0);
              }
            });
          }
          //loop through event days
          if( event.event_days != undefined && event.event_days.length > 0 ){
            event.event_days.forEach((eventDay) => {
              //if event day matches date data attribute
              if( eventDay.day === dataDay ) {
                if( eventDay.start_date <= dataDate && eventDay.end_date >= dataDate ) {
                  date.classList.add('has-event');
                  date.setAttribute('tabindex', 0);
                }
              }
            });
          }
        });
      });
    },


    initializeCalendar (justRefresh = false) {
      if(justRefresh == false){
        console.log('just refresh is false');
        let eventsCalendarElem = this.widgetBlock.querySelector('.events-calendar');
        if( eventsCalendarElem ) {

          console.log('eventsCalendarElem found');

          eventsCalendar = jsCalendar.new(eventsCalendarElem);  //eslint-disable-line

          eventsCalendar.onDateRender((date, element, info) => { //eslint-disable-line
            //wait until retrieveEventsTimeoutIsActive is false
            element.classList.add('jsCalendar-day');

            //on keypress enter, click
            element.addEventListener('keypress', (e) => {
              if(e.keyCode == 13){
                element.click();
              }
            });

            if( info.isCurrentMonth == true || date.getDate() == 1 ) {
              this.eventFoundOnDate = false;

              if( this.calendarType == 'booking' ) { //events for booking calendar don't necessarily have a specific date, instead a range.

                let daySlug = '';
                switch(date.getDay()) {
                  case 0:
                    daySlug = 'sunday';
                    break;
                  case 1:
                    daySlug = 'monday';
                    break;
                  case 2:
                    daySlug = 'tuesday';
                    break;
                  case 3:
                    daySlug = 'wednesday';
                    break;
                  case 4:
                    daySlug = 'thursday';
                    break;
                  case 5:
                    daySlug = 'friday';
                    break;
                  case 6:
                    daySlug = 'saturday';
                    break;
                }

                //add dayslug to data attribute
                element.setAttribute('data-day', daySlug);
              }

              element.setAttribute('data-date', date.yyyymmdd());
              if( this.calendarType == 'booking' ) {
                element.classList.add('booking-calendar');
              }

              if( info.isCurrent && this.monthHasChanged == false && this.calendarType == 'booking'  ){
                setTimeout(() => {
                  element.click();
                }, 2000);
              }
            }else{
              element.classList.remove('has-event');
              element.classList.remove('no-event');
              element.setAttribute('tabindex', '');
            }
          });

          eventsCalendar.onDateClick((event, date) => { //eslint-disable-line
            if( this.widgetBlock.querySelector('.jsCalendar-current') ){
              this.widgetBlock.querySelector('.jsCalendar-current').classList.remove('jsCalendar-current');
            }
            event.target.classList.add('jsCalendar-current');

            if( this.calendarType != 'booking' ){
              let events = this.widgetBlock.querySelectorAll('.upcoming-events__event');
              events.forEach((event, index) => {
                let eventDates = event.querySelectorAll('.hidden-date-attribute');
                eventDates.forEach((eventDate) => {
                  if( eventDate.dataset.date === date.yyyymmdd() ) {
                    eventsSlider.select( index, false, false )
                  }
                });
              });
            }else{
              //console.log('booking calendar date click');
              this.currentDayElement = event.target;
              this.previousDayElement = event.target.previousElementSibling;
              this.nextDayElement = event.target.nextElementSibling;

              // this is a booking calendar, so we need to check the day of the week
              //convert numerical day into slug
              let daySlug = '';
              let dayName = '';
              switch(date.getDay()) {
                case 0:
                  daySlug = 'sunday';
                  dayName = 'Sunday';
                  break;
                case 1:
                  daySlug = 'monday';
                  dayName = 'Monday';
                  break;
                case 2:
                  daySlug = 'tuesday';
                  dayName = 'Tuesday';
                  break;
                case 3:
                  daySlug = 'wednesday';
                  dayName = 'Wednesday';
                  break;
                case 4:
                  daySlug = 'thursday';
                  dayName = 'Thursday';
                  break;
                case 5:
                  daySlug = 'friday';
                  dayName = 'Friday';
                  break;
                case 6:
                  daySlug = 'saturday';
                  dayName = 'Saturday';
                  break;
              }

              this.currentDayName = dayName + ', ' + date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate();
              this.currentDate = date.yyyymmdd();


              let events = this.widgetBlock.querySelectorAll('.upcoming-events__event');
              events.forEach((event) => {
                let thisEventOccursOnThisDay = false;
                let eventDays = event.querySelectorAll('.hidden-day-attribute');

                eventDays.forEach((eventDay) => {
                  if( eventDay.dataset.day === daySlug ) {
                    if( eventDay.dataset.startdate <= date.yyyymmdd() && eventDay.dataset.enddate >= date.yyyymmdd() ) {
                      thisEventOccursOnThisDay = true;
                      event.querySelector('.upcoming-events__event__info__timeblock .starttime').innerHTML = eventDay.dataset.starttime;
                      event.querySelector('.upcoming-events__event__info__timeblock .endtime').innerHTML = eventDay.dataset.endtime;


                      //conver the start time into a 24 hour time
                      let startTime = eventDay.dataset.starttime;
                      event.setAttribute('data-starttime', startTime);
                    }
                  }
                });

                if( thisEventOccursOnThisDay ) {
                  event.style.display = 'block';
                  //add event-is-visible class to the event
                  event.classList.add('event-is-visible');
                }else{
                  event.style.display = 'none';
                  //remove event-is-visible class from the event
                  event.classList.remove('event-is-visible');
                }

              });

              //grab all events with display block
              let visibleEvents = this.widgetBlock.querySelectorAll('.event-is-visible');
              const divArray = Array.from(visibleEvents);
              divArray.sort((a, b) => {
                const startTimeA = a.getAttribute('data-starttime');
                const startTimeB = b.getAttribute('data-starttime');

                const dateA = new Date(`2000-01-01 ${startTimeA}`);
                const dateB = new Date(`2000-01-01 ${startTimeB}`);

                // Compare the Date objects
                return dateA.getTime() - dateB.getTime();

              });

              //get the parent div of the events
              let eventsParent = this.widgetBlock.querySelector('.upcoming-events__events');
              //remove all events with display block from the parent div
              visibleEvents.forEach((event) => {
                eventsParent.removeChild(event);
              });
              //append the events back to the parent div
              divArray.forEach((event) => {
                eventsParent.appendChild(event);
              });

              //if current target has class no-event
              if( this.widgetBlock.querySelector('.jsCalendar-current').classList.contains('no-event') ) {
                //console.log('no event on this day');
                this.widgetBlock.querySelector('.events__no-results').style.display = 'block';
              }else{
                this.widgetBlock.querySelector('.events__no-results').style.display = 'none';
              }
            }

          });

          eventsCalendar.onMonthChange((event, date) => { //eslint-disable-line

            let previousCurrentDate = this.currentDate;
            this.currentMonthDate = date.yyyymmdd();
            this.currentDate = date.yyyymmdd();

            if( this.calendarType == 'booking' ) {
              if( previousCurrentDate < this.currentDate ) {
                setTimeout(() => {
                  let dateElement = this.widgetBlock.querySelector('[data-date="' + this.currentDate + '"]');
                  if( dateElement ){
                    this.dateElement = dateElement;
                  }else{
                    this.dateElement = null
                  }
                }, 100);
              }else{
                //if date is the last day of the month
                date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                this.currentDate = date.yyyymmdd();
                setTimeout(() => {
                  let dateElement = this.widgetBlock.querySelector('[data-date="' + this.currentDate + '"]');
                  if( dateElement ){
                    this.dateElement = dateElement;
                  }else{
                    this.dateElement = null
                  }
                }, 100);
              }

              let dayName = '';
              switch(date.getDay()) {
                case 0:
                  dayName = 'Sunday';
                  break;
                case 1:
                  dayName = 'Monday';
                  break;
                case 2:
                  dayName = 'Tuesday';
                  break;
                case 3:
                  dayName = 'Wednesday';
                  break;
                case 4:
                  dayName = 'Thursday';
                  break;
                case 5:
                  dayName = 'Friday';
                  break;
                case 6:
                  dayName = 'Saturday';
                  break;
              }

              this.currentDayName = dayName + ', ' + date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate();
            }

            eventsCalendar.set(date);
            eventsCalendar.goto(date);

            this.widgetBlock.querySelector('.datepicker--from').value = dateFormat(date, 'dd/mm/yyyy');
            this.widgetBlock.querySelector('.datepicker--to').value = dateFormat(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'dd/mm/yyyy');

            //this.retrieveEvents();
            if( this.retrieveEventsTimeoutIsActive ) {
              this.resetRetrieveEventsTimeout();
            } else {
              this.startRetrieveEventsTimeout();
            }

            this.monthHasChanged = true;
          });

          if( this.calendarType == 'booking' ) {
            let previousDayButton = this.widgetBlock.querySelector('.booking-calendar-buttons__prev');
            let nextDayButton = this.widgetBlock.querySelector('.booking-calendar-buttons__next');

            previousDayButton.addEventListener('click', () => { //eslint-disable-line
              let nextDate = new Date(this.currentDate + 'T00:00:00');
              nextDate.setDate(nextDate.getDate() - 1);
              this.currentDate = nextDate.yyyymmdd();

              //if nextDate is the last day of the month
              if( nextDate.getDate() == new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0).getDate() ) {
                this.widgetBlock.querySelector('.jsCalendar-nav-left').click();
                setTimeout(() => {
                  let dateElement = this.widgetBlock.querySelector('[data-date="' + nextDate.yyyymmdd() + '"]');
                  if( dateElement ){
                    this.dateElement = dateElement;
                    dateElement.click();
                  }
                }, 100);
              } else{
                let dateElement = this.widgetBlock.querySelector('[data-date="' + nextDate.yyyymmdd() + '"]');
                if( dateElement ){
                  this.dateElement = dateElement;
                  dateElement.click();
                }
              }

              eventsCalendar.set(nextDate);
              eventsCalendar.goto(nextDate);

            });

            nextDayButton.addEventListener('click', () => { //eslint-disable-line
              let nextDate = new Date(this.currentDate + 'T00:00:00');
              nextDate.setDate(nextDate.getDate() + 1);
              this.currentDate = nextDate.yyyymmdd();

              //if nextDate is the first day of the next month
              if( nextDate.getDate() == 1 ) {
                this.widgetBlock.querySelector('.jsCalendar-nav-right').click();
                setTimeout(() => {
                  let dateElement = this.widgetBlock.querySelector('[data-date="' + nextDate.yyyymmdd() + '"]');
                  if( dateElement ){
                    this.dateElement = dateElement;
                    dateElement.click();
                  }
                }, 100);
              } else{
                let dateElement = this.widgetBlock.querySelector('[data-date="' + nextDate.yyyymmdd() + '"]');
                if( dateElement ){
                  this.dateElement = dateElement;
                  dateElement.click();
                }
              }

              eventsCalendar.set(nextDate);
              eventsCalendar.goto(nextDate);
            });

          }
          eventsCalendar.refresh();
        }
      }else{
        if( eventsCalendar ) {
          eventsCalendar.refresh();
        }
        setTimeout(() => {
          this.flagDates();
        }, 1000);
      }

      let navLeft = this.widgetBlock.querySelector('.jsCalendar-nav-left');
      let navRight = this.widgetBlock.querySelector('.jsCalendar-nav-right');

      if( navLeft ) {
        //add tabindex 0 if not there
        if( !navLeft.hasAttribute('tabindex') ) {
          navLeft.setAttribute('tabindex', 0);
          //on keypress enter, click
          navLeft.addEventListener('keypress', (e) => {
            if( e.keyCode == 13 ) {
              navLeft.click();
            }
          });
        }
      }

      if( navRight ) {
        //add tabindex 0 if not there
        if( !navRight.hasAttribute('tabindex') ) {
          navRight.setAttribute('tabindex', 0);
          //on keypress enter, click
          navRight.addEventListener('keypress', (e) => {
            if( e.keyCode == 13 ) {
              navRight.click();
            }
          });
        }
      }
    },

    initSlider() {
        let eventsCalendarEvents = this.widgetBlock.querySelector('.upcoming-events__events');
        if( eventsCalendarEvents ) {

          if( this.isFlickityInitialized ){
            this.uninitSlider(true);
          }
          eventsSlider = new Flickity( eventsCalendarEvents, { //eslint-disable-line
            contain: true,
            prevNextButtons: true,
            pageDots: false,
            cellAlign: 'left',
          });
          eventsSlider.reloadCells();
          this.isFlickityInitialized = true;

          let events = this.widgetBlock.querySelectorAll('.upcoming-events__event');
          let foundEventToday = false;
          events.forEach((event, index) => {
            let eventDates = event.querySelectorAll('.hidden-date-attribute');
            eventDates.forEach((eventDate) => {
              if( eventDate.dataset.date === this.currentDate  && foundEventToday == false ) {
                eventsSlider.select( index, false, false );
                foundEventToday = true;
              }
            });
          });
        }
    },

    uninitSlider(initAfter = false) {
      if( this.calendarType != 'booking' ) {
        if( this.isFlickityInitialized ){
          eventsSlider.destroy();
          this.isFlickityInitialized = false;
        }

        if( initAfter ){
          this.initSlider();
        }
      }
    },

    changeView(mode, retry = false){
      if( mode == 'month' ){
        if( this.viewMode == 'month' ){
          if( this.calendarType != 'booking' ) {
            this.initSlider();
          }
        }else{
          this.tab = 4;
          this.viewMode = 'month';
          this.updateDates('month', true);
          eventsCalendar.set(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
          eventsCalendar.goto(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
        }
      }else{
        this.viewMode = 'event';
        this.uninitSlider();

        switch (this.tab) {
          case 1:
            this.updateDates('all', true);
            break;
          case 2:
            this.updateDates('today', true);
            break;
          case 3:
            this.updateDates('week', true);
            break;
          case 4:
            this.updateDates('month', true);
            break;
        }
      }

      if( retry ){
        //wait until .upcoming-events__spinner is found in widgetblock and has style display none
        let interval = setInterval(() => {
          if( this.widgetBlock.querySelector('.upcoming-events__spinner').style.display == 'none' ){
            setTimeout(()=>{this.changeView(mode, false);},100);
            clearInterval(interval);
          }
        }, 100);
      }

    },

    retrieveEvents(loadMore = false) {
      //if( this.pendingRetrieveEvents == false ) {
        //this.pendingRetrieveEvents = true;
        if( loadMore == true ) {
          this.isInitialLoad = false;
        }
        ////console.log('retrieve events called');
        this.handleDates();
        if( this.calendarType != 'booking' ) {
          this.uninitSlider();
        }
        setTimeout(() => {
          this.widgetBlock.querySelector('.posts--loadmore--event').click();
        }, 200);
        this.updateIsInitialLoad();
      //}
    },

    startRetrieveEventsTimeout(loadMore = false) {
      this.retrieveEventsTimeoutIsActive = true;
      this.retrieveEventsTimeoutId = setTimeout(() => {
        this.retrieveEvents(loadMore);
        ////console.log('retrieve events timeout called');
      }, 1000);
    },

    resetRetrieveEventsTimeout() {
      //this.retrieveEventsTimeoutIsActive = false;
      clearTimeout(this.retrieveEventsTimeoutId);
      this.startRetrieveEventsTimeout();
    },

    updateIsInitialLoad() {
      if( this.isInitialLoad == true && this.eventsCount >= 4 ){
        this.isInitialLoadAndEventsCountAtLeastFour = true;
      }else{
        this.isInitialLoadAndEventsCountAtLeastFour = false;
      }
    },
}));
