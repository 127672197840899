import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import CSSRulePlugin from 'gsap/dist/CSSRulePlugin';
import Flickity from 'flickity';
import Alpine from 'alpinejs'
import mediumZoom from 'medium-zoom';
import './_retrieve-events.js';
import './jquery.fittext.js';

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

export default {
  init() {
    // JavaScript to be fired on all pages
    gsap.core.globals('ScrollTrigger', ScrollTrigger);
  },
  finalize() {
    Alpine.data('cookieBanner', () => ({
        open: true,

        accept() {
          this.open = ! this.open;
          this.setCookie('real_district_cookies_accepted', 1, 90);
        },

        decline() {
            this.open = ! this.open
        },

        setCookie(name,value,days) {
            var expires = '';
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + (value || '')  + expires + '; path=/';
        },
    }));

    Alpine.data('sidebarMenu', () => ({
      dropdown: [1],

      init() {
        this.checkIndexes();
      },

      toggleElement(thisDropdown, val){
        if(thisDropdown.includes(val)){
          return thisDropdown.filter(el => el !== val);
        } else {
          return [...thisDropdown, val];
        }
      },

      toggleDropdown(val){
        this.dropdown = this.toggleElement(this.dropdown, val);
      },

      checkIndexes(){
        let currentPageMenuItems = document.querySelectorAll('.page-menu__item.current-menu-item');
        currentPageMenuItems.forEach((item) => {
          let thisIndex = parseInt(item.getAttribute('data-menuindex'));
          if( thisIndex ){
            if( !this.dropdown.includes(thisIndex) ){
              this.dropdown.push(thisIndex);
            }
          }
        });
      },
    }));

    Alpine.start();
    mediumZoom('[data-zoomable]');

    const parallaxBanners = document.querySelectorAll('.parallax-banner .parallax-img, .cta-banner .parallax-img');
    ScrollTrigger.saveStyles(parallaxBanners);
    // Animate the parallax banners
    parallaxBanners.forEach((img, index) => {
      let thisID = 'parallax-banner-' + index+1;
      gsap.fromTo(img,{
        yPercent: 0,
        id: thisID,
        ease: 'ease',
        stagger: 15,
        scrollTrigger: {
          trigger: img,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,
        },
      },{
        yPercent: -15,
        id: thisID,
        ease: 'ease',
        stagger: 0,
        scrollTrigger: {
          trigger: img,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,
        },
      });
    });

    const navSliderElem = document.querySelector('.nav-slider__slider');
    if( navSliderElem ) {
      let navSlider = new Flickity( navSliderElem, { //eslint-disable-line
        // options
        cellAlign: 'left',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
      });
    }

    const featuredEventsSliderElem = document.querySelector('.featured-events__slider');
    if( featuredEventsSliderElem ) {
      let featuredEventsSlider = new Flickity( featuredEventsSliderElem, { //eslint-disable-line
        // options
        cellAlign: 'left',
        contain: true,
        pageDots: false,
      });
    }

    const featuredMinorTeamsSliderElem = document.querySelector('.featured-teams__minor-teams');
    if( featuredMinorTeamsSliderElem ) {
      let featuredMinorTeamsSlider = new Flickity( featuredMinorTeamsSliderElem, { //eslint-disable-line
        // options
        contain: true,
        pageDots: true,
      });
    }

    const featuredMajorTeamsSliderElem = document.querySelector('.featured-teams__major-teams');
    if( featuredMajorTeamsSliderElem ) {
      let featuredMajorTeamsSlider = new Flickity( document.querySelector('.featured-teams__major-teams'), { //eslint-disable-line
        // options
        contain: true,
        pageDots: false,
        prevNextButtons: true,
      });
    }

    const featuredProductsSliderElem = document.querySelector('.featured-products__products');
    if( featuredProductsSliderElem ) {
      let featuredProductsSlider = new Flickity( featuredProductsSliderElem, { //eslint-disable-line
        // options
        contain: true,
        pageDots: false,
        prevNextButtons: true,
      });
    }

    const featuredPDFsSliderElem = document.querySelector('.pdfs-listing__featured-pdfs');
    if( featuredPDFsSliderElem ) {
      let featuredPDFsSlider = new Flickity( featuredPDFsSliderElem, { //eslint-disable-line
        // options
        contain: true,
        pageDots: false,
        prevNextButtons: true,
        groupCells: true,
        cellAlign: 'left',
      });
    }

    const imageSliderElems = document.querySelectorAll('.image-slider__slides');
    if( imageSliderElems ) {
      imageSliderElems.forEach((slider) => {
        let imageSlider = new Flickity( slider, { //eslint-disable-line
          // options
          wrapAround: true,
          pageDots: false,
          prevNextButtons: true,
        });
      });
    }

    const wysiwygIframes = document.querySelectorAll('.wysiwyg__inner iframe');
    if( wysiwygIframes ) {
      wysiwygIframes.forEach((iframe) => {
        //wrap in .embed-container div
        let iframeParent = iframe.parentNode;
        let iframeWrapper = document.createElement('div');
        iframeWrapper.classList.add('embed-container');
        iframeParent.insertBefore(iframeWrapper, iframe);
        iframeWrapper.appendChild(iframe);
      });
    }


    $('.video-poster').on('click', function(){
      let thisVideoBlockID = $(this).attr('data-id');
      let thisVideoType = $(this).attr('data-type');
      let $this = $(this);
      $(this).fadeOut('fast');
      $(thisVideoBlockID).find('.video-play').fadeOut('fast');

      if( thisVideoType == 'selfhosted' ){
        $(thisVideoBlockID).find('video').get(0).play();
      }

      if( thisVideoType == 'youtube' ){
        $(thisVideoBlockID).find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
      }

      if( thisVideoType == 'vimeo' ){
        let iframe = $(thisVideoBlockID).find('iframe');
        let player = new Vimeo.Player(iframe); //eslint-disable-line no-undef
        player.play();
      }

      setTimeout(function(){
        $this.hide();
      },210);
    });

    const body = document.querySelector('body');
    const pageMenu = document.querySelector('.page__page-menu');
    const sidebarMenu = document.querySelector('.sidebar-plus-content__sidebar');
    //media query for min-width: 960px
    const mq960 = window.matchMedia( '(min-width: 960px)' );

    if( pageMenu && sidebarMenu ){
      window.addEventListener('scroll', function() {
        if(mq960.matches){
          if (window.scrollY > (sidebarMenu.offsetTop + sidebarMenu.offsetHeight)) {
            pageMenu.classList.add('page__page-menu--shown');
            body.classList.add('body--page-menu--shown');
          }else{
            pageMenu.classList.remove('page__page-menu--shown');
            body.classList.remove('body--page-menu--shown');
          }
        }
      });
    }

    if( pageMenu && !sidebarMenu ){
      window.addEventListener('load resize', function() {
        if(mq960.matches){
          pageMenu.classList.add('page__page-menu--shown');
          body.classList.add('body--page-menu--shown');
        }else{
          pageMenu.classList.remove('page__page-menu--shown');
          body.classList.remove('body--page-menu--shown');
        }
      });
    }

    function resizeListFeatureTitles(){
      const listFeatures = document.querySelectorAll('.list-feature');
      if( listFeatures ){
        listFeatures.forEach((feature) => {
          let titleHeight = 0.
          let listColumns = feature.querySelectorAll('.list-feature__column');
          listColumns.forEach((column) => {
            if( column.querySelector('h6') ){
              let thisTitleHeight = column.querySelector('h6').offsetHeight;
              if( thisTitleHeight > titleHeight ){
                titleHeight = thisTitleHeight;
              }
            }
          });

          listColumns.forEach((column) => {
            if( column.querySelector('h6') ){
              column.querySelector('h6').style.height = titleHeight + 'px';
            }
          });
        });
      }
    }
    resizeListFeatureTitles();

    function scrollMobileMenu(){
      const mobileMenu = document.querySelector('.subpage-header__page-menu__items');
      if( mobileMenu ){
        let currentMenuItem = mobileMenu.querySelector('.current-menu-item');
        if( currentMenuItem ){
          mobileMenu.scrollLeft = currentMenuItem.offsetLeft - 32;
        }
      }
    }

    function addTabIndexToAllLinks(){
      const allLinks = document.querySelectorAll('a');
      if( allLinks ){
        allLinks.forEach((link) => {
          if( !link.hasAttribute('tabindex') ){
            link.setAttribute('tabindex', '0');
          }
        });
      }
    }

    window.addEventListener('load', function() {
      resizeListFeatureTitles();
      scrollMobileMenu();
      addTabIndexToAllLinks();

      $('.home-about__stat__number').each(function () {
        $(this).fitText(0.4);
      });
    });

    window.addEventListener('resize', function() {
      resizeListFeatureTitles();

      $('.home-about__stat__number').each(function () {
        $(this).fitText(0.4);
      });
    });
  },
};
